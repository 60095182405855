<template>
	<section class="bg-pink section-3">
		<div class="container min-vh-100 py-5">
			<div class="row">
				<div class="col-12">
					<h2 class="h1 mt-5 col-md-8">La méthode PAWPAW</h2>
					<div class="col-12"></div>
					<p class="p-important col-md-8"> Bienvenue dans l'univers de la méthode PAWPAW, où l'innovation et l'originalité redéfinissent l'éducation canine. Notre philosophie s'ancre dans une compréhension profonde des besoins et du comportement canin, visant à établir
						une relation harmonieuse et respectueuse entre vous et votre compagnon. </p>
					<!-- <h3 class="mt-5 h1">Nos Trois Piliers&thinsp;:</h3> -->
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-12">
					<h2 class="h1 mt-5">Nos services incluent&thinsp;:</h2>
				</div>
				<div class="col-12 my-5"></div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded">
						<h3 class="p-important">Séances d'entraînement personnalisées</h3>
						<p> Chez PAWPAW, chaque chien est unique. Nos séances d'entraînement personnalisées sont conçues pour répondre aux besoins spécifiques de votre compagnon. Nous couvrons des compétences essentielles telles que : </p>
						<ul>
							<li><b>Assis, Couché, Debout :</b> Des instructions de base pour améliorer l'écoute de votre chien. </li>
							<li><b>Stop :</b> Utilisé pour garantir la sécurité et le contrôle dans différentes situations. </li>
							<li><b>Marche en laisse :</b> Pour des promenades détendues et agréables. </li>
							<li><b>Pas bougé :</b> Pour cultiver la patience et la gestion de l'impulsivité chez votre chien. </li>
							<li><b>Rappel :</b> Pour assurer une réponse fiable de votre chien en toutes circonstances. </li>
						</ul>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded block-green-second">
						<h3 class="p-important"> Solutions pour divers troubles du comportement </h3>
						<p> Nous abordons une gamme de troubles comportementaux, en offrant des stratégies personnalisées pour chaque cas : </p>
						<ul>
							<li><b>Anxiété de séparation :</b> Aider votre chien à se sentir en sécurité même en votre absence. </li>
							<li><b>Troubles de l'attention et de la concentration :</b> Améliorer la focalisation de votre chien. </li>
							<li><b>Malpropreté :</b> Des solutions pour une hygiène irréprochable. </li>
							<li><b>Aboiements excessifs :</b> Réduire les nuisances sonores. </li>
							<li><b>Destructions, Fugues, Sauts, Vols :</b> Gérer les comportements destructeurs et inappropriés. </li>
							<li><b>Agressivité :</b> Vers une cohabitation pacifique avec les humains et autres animaux. </li>
							<li><b>Crainte :</b> Renforcer la confiance et la sécurité.</li>
						</ul>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded">
						<h3 class="p-important">Conseils en comportement</h3>
						<p> Comprendre votre chien est la clé d'une relation harmonieuse. Nous vous aidons à interpréter ses actions et réactions pour mieux répondre à ses besoins et comportements, en créant un environnement propice à son épanouissement.
							</p>
					</div>
				</div>
				<div class="col-12 my-5 section-2-r2"></div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded">
						<h3 class="p-important">Cours collectifs</h3>
						<p> Nos cours collectifs offrent une excellente opportunité pour la socialisation et l'apprentissage en groupe. Ils permettent à votre chien d'interagir avec ses pairs dans un cadre contrôlé et éducatif. </p>
					</div>
				</div>
				<div class="col-md-4">
					<div class="block-green block-pink px-4 py-5 rounded block-green-second">
						<h3 class="p-important">Promenades Éducatives</h3>
						<p> Nos promenades éducatives allient exercice physique et entraînement comportemental. Elles sont conçues pour renforcer les apprentissages tout en profitant de l'extérieur. </p>
					</div>
				</div>
				<div class="col-12 mb-5"></div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>